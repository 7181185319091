import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage, GatsbyImage } from "gatsby-plugin-image"




export default function fourOfourPage ( { data } ) {
const seo = {
      metaTitle: "Oops DONT PANIC! Error - 404 - Toristy",
      metaDescription: data.strapiGlobal.defaultSeo.metaDescription,
    }


const bodyImage = getImage(data.fourOfourBg)

return (
        <Layout>
          <Seo seo={seo} />
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
              <div className="">
                <GatsbyImage image={bodyImage} alt="Lost Traveller?" />
              </div>
                <div>
                  <h2 className="p-1 text-2xl text-brandgreen font-semibold md:text-4xl">Lost? Maybe these links might help? (404 error page)</h2>
                  <div className="p-4">
                    <div className="my-4"><a href="https://www.toristy.travel" className="bg-brandblue text-center text-white py-2 px-4 rounded-lg focus:ring focus:ring-blue-300 hover:bg-brandgreen">Visit our marketplace</a></div>
                    <div className="my-4"><a href="https://portal.toristy.com/" className="bg-brandblue text-center text-white py-2 px-4 rounded-lg focus:ring focus:ring-blue-300 hover:bg-brandgreen">Login to our supplier portal</a></div>
                    <div className="my-4"><Link to="/contact" className="bg-brandblue text-center text-white py-2 px-4 rounded-lg focus:ring focus:ring-blue-300 hover:bg-brandgreen">Drop us a line with your query</Link></div>
                  </div>
                </div>
          </div>
        </Layout>
      )
    }

// query from Strapi

export const query = graphql`    
query fourOfourQuery {
    strapiGlobal {
        defaultSeo {
          metaDescription
        }
      }
      fourOfourBg: file(relativePath: {eq: "images/404.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
  }
  `
